import { Stream } from '@cloudflare/stream-react'
import type { ReferenceRendererProps, RichTextReference } from '@contember/react-client'
import React, { FunctionComponent } from 'react'
import { ContentImageType } from '../../../generated/content'
import { Dump } from '../../../libs/dump'
import { StripeProvider } from '../../../libs/stripe/StripeProvider'
import { filterNonEmpty } from '../../../libs/utils/filterNonEmpty'
import type { ContentBlockReference } from '../../data/fragments/ContentFragment'
import type { PaymentBoxResult } from '../../data/fragments/PaymentBoxFragment'
import { useArticle } from '../../utils/ArticleContext'
import { pruneObject } from '../../utils/pruneObject'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Discussion } from '../Discussion'
import { Embed } from '../Embed'
import { FomoFeedItem } from '../FomoFeed'
import { Gallery } from '../Gallery'
import { ImageCaption } from '../ImageCaption'
import { ImageFloat } from '../ImageFloat'
import { OEmbed } from '../OEmbed'
import { PaymentBox, PaymentBoxMetadata } from '../PaymentBox'
import { ProductTiles } from '../ProductTiles'
import { PromoCodes } from '../PromoCodes'
import { Quote } from '../Quote'
import { smallOnDesktop, Tile, TileStyleContext } from '../Tile'
import { MaybeRichText } from './MaybeRichText'
import style from './ReferenceRenderers.module.sass'
import { ProductOfTheMonth } from '../ProductOfTheMonth'
import { useSharedContext } from '../../../libs/routing/sharedContext'
import { richTextToPlaintext } from '../../../libs/contember/richTextToPlaintext'

const SHOW_DUMPS = Number(process.env.LOG_LEVEL) > 8

type ReferenceRenderers<Block extends RichTextReference & { type: string | number | symbol }> = {
	[Property in Block['type']]: (
		props: ReferenceRendererProps<Block & { type: Property }>
	) => React.ReactNode
}

export const referenceRenderers: ReferenceRenderers<ContentBlockReference> = {
	embed(ref) {
		return <>{ref.reference.embed && <Embed embed={ref.reference.embed} />}</>
	},
	oembed(ref) {
		return <OEmbed oembed={String(ref.reference.primaryText)} />
	},
	feed(ref) {
		return (
			<Container>
				{SHOW_DUMPS && <Dump data={pruneObject(ref.reference, { arrays: true })} />}
				{ref.reference.feedVideos.map(
					(vid, index) =>
						vid.video?.video?.videoOid && (
							<div key={vid.video.id} style={{ maxWidth: 270, margin: 'auto' }}>
								<FomoFeedItem video={vid.video} position={index + 1} />
							</div>
						)
				)}
			</Container>
		)
	},
	gallery(ref) {
		return (
			<>
				<Gallery
					id={ref.reference.id}
					items={ref.reference.images
						.map((image) => image.image)
						.filter(filterNonEmpty)
						.map((image) => ({ type: 'image', image }))}
				/>
			</>
		)
	},
	promo_codes(ref) {
		return <>{ref.reference.promoCodes && <PromoCodes codes={ref.reference.promoCodes?.items} />}</>
	},
	discussion(ref) {
		return (
			<DiscussionWrapper heading={ref.reference.primaryText} enteringBtnText={ref.reference.text} />
		)
	},
	image(ref) {
		let content: React.ReactNode = ref.reference.image?.image && (
			<figure>
				<ContemberImage image={ref.reference.image.image} />
				{ref.reference.image.image?.description && (
					<ImageCaption caption={ref.reference.image.image?.description} />
				)}
			</figure>
		)

		if (ref.reference.image?.type === ContentImageType.text_width) {
			content = (
				<Container size="narrow" disableGutters>
					{content}
				</Container>
			)
		}

		if (ref.reference.image?.type === ContentImageType.float) {
			content = (
				<Container size="wide" disableGutters>
					<figure>
						<ImageFloat float={ref.reference.image.float ?? 'left'}>{content}</ImageFloat>
						{ref.reference.image.image?.description && (
							<ImageCaption caption={ref.reference.image.image?.description} />
						)}
					</figure>
				</Container>
			)
		}
		return (
			<>
				{SHOW_DUMPS && <Dump data={pruneObject(ref.reference, { arrays: true })} />}
				{content}
			</>
		)
	},
	link(ref) {
		return (
			<>
				<pre>{JSON.stringify(ref, null, 2)}</pre>
			</>
		)
	},
	html(ref) {
		return (
			<>
				{ref.reference.text && <div dangerouslySetInnerHTML={{ __html: ref.reference.text }}></div>}
			</>
		)
	},
	payment_box(ref) {
		return (
			<StripeProvider>
				{ref.reference.paymentBox && <PaymentBoxWrapper {...ref.reference.paymentBox} />}
			</StripeProvider>
		)
	},
	paywall_splitter() {
		return <></>
	},
	products(ref) {
		return (
			<>
				<ProductTiles
					products={ref.reference.products.map((p) => p.product).filter(filterNonEmpty)}
					typeView="slider"
				/>
			</>
		)
	},
	quote(ref) {
		return (
			<Container size="normal">
				<Quote
					primary={ref.children}
					secondary={<MaybeRichText>{ref.reference.text ?? ''}</MaybeRichText>}
					color={ref.reference.color}
				/>
			</Container>
		)
	},
	product_banner: function ProductBanner() {
		const context = useSharedContext()
		return (
			<>
				<ProductOfTheMonth
					title={context.header.productBanner?.title}
					subtitle={context.header.productBanner?.subtitle}
					image={context.header.productBanner?.image}
					linkLabel={context.header.productBanner?.linkLabel}
					link={context.header.productBanner?.link}
					noHeader
					articleLocated
				/>
			</>
		)
	},
	related_article(ref) {
		return (
			<>
				<TileStyleContext.Provider value={smallOnDesktop}>
					<div className={style.SmallTitle}>{ref.reference.text}</div>
					{ref.reference.article && (
						<Tile
							{...ref.reference.article}
							title={richTextToPlaintext(ref.reference.article.title)}
							author={ref.reference.article.authors[0]}
							image={ref.reference.article.mainImage}
							tags={ref.reference.article.tags.map((t) => t.tag).filter(filterNonEmpty)}
							promoPartner={ref.reference.article.promoPartner}
							gtm={{
								id: ref.reference.article.id,
								title: richTextToPlaintext(ref.reference.article.title),
								list: 'articleContent',
							}}
						/>
					)}
				</TileStyleContext.Provider>
			</>
		)
	},
	video(ref) {
		return (
			<div>
				{ref.reference.video?.videoOid && (
					<Stream src={ref.reference.video?.videoOid} preload controls />
				)}
			</div>
		)
	},
}

const PaymentBoxWrapper: FunctionComponent<PaymentBoxResult> = (props) => {
	const article = useArticle()
	const articleId = article.id
	const paymentBoxId = props.id

	const metadata = React.useMemo<PaymentBoxMetadata>(() => {
		return {
			articleId,
			paymentBoxId,
		}
	}, [articleId, paymentBoxId])

	return <PaymentBox {...props} metadata={metadata} authors={article.authors} />
}

const DiscussionWrapper: FunctionComponent<{
	heading?: string
	enteringBtnText?: string
}> = (props) => {
	const article = useArticle()
	const pinnedPost = article.pinnedDiscussionPost?.id
	return (
		<>
			<Discussion
				entering
				heading={props.heading}
				enteringBtnText={props.enteringBtnText}
				pinnedPostId={pinnedPost}
			/>
		</>
	)
}
